import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import Title from "../components/title"
import tw from "twin.macro"

const ContactPage: React.FC = () => {
  const Container = styled.section`
    ${tw`
      flex flex-col items-center self-center
      md:w-8/12
     `};
  `

  const TitleContainer = styled.div`
    ${tw`md:w-full`};
  `

  const Label = styled.label`
    ${tw`mb-2`};
  `

  const Form = styled.form`
    ${tw`flex flex-col font-content w-full`};
  `
  const Input = styled.input`
    ${tw`shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`};
    color: #4a5568;
  `

  const Textarea = styled.textarea`
    ${tw`shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline h-64`};
    color: #4a5568;
  `

  const Button = styled.button`
    ${tw`mt-4 bg-linkcolor font-bold rounded py-2 px-4 focus:outline-none focus:shadow-outline max-w-xs`};
    color: #fff;
  `

  const Row = styled.div`
    ${tw`mt-4 flex flex-col`};
  `

  return (
    <Layout>
      <SEO title="Contact" />
      <Container>
        <TitleContainer>
          <Title title={"Contact"} />
        </TitleContainer>
        <Form
          name="contact"
          method="POST"
          action={"/message-received/"}
          data-netlify="true"
          netlify-honteypost={"bot-field"}
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" value="contact" />
          <Row>
            <Label htmlFor={"name"}>Name</Label>
            <Input
              type={"text"}
              name={"name"}
              id={"name"}
              placeholder={"Name"}
              required
            />
          </Row>
          <Row>
            <Label htmlFor={"email"}>Email</Label>
            <Input
              type={"email"}
              name={"email"}
              id={"email"}
              placeholder={"Email"}
              required
            />
          </Row>
          <Row>
            <Label htmlFor={"message"}>Message</Label>
            <Textarea
              name={"message"}
              id={"message"}
              placeholder={"Message"}
              required
            />
          </Row>
          <Button type={"submit"}>Send</Button>
        </Form>
      </Container>
    </Layout>
  )
}

export default ContactPage
